import * as React from 'react';
import { Link } from 'gatsby';

const BlogPostLink = ({ post }) => (
  <div>
    <div className={'post-header'}>
      <Link to={post.frontmatter.path} className={'post-title'}>
        <h2>{post.frontmatter.title}</h2>
      </Link>
      <div className={'post-date'}>{post.frontmatter.date}</div>
    </div>
    <p className={'post-excerpt'}>{post.excerpt}</p>
  </div>
);

export default BlogPostLink;
