import * as React from 'react';
import { graphql } from 'gatsby';
import BlogPostLink from '../components/blogpost/BlogPostLink';
import PageWrapper from '../components/pagewrapper/pageWrapper';
import { Grid } from 'semantic-ui-react';

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <BlogPostLink key={edge.node.id} post={edge.node} />);
  return (
    <PageWrapper activeItem={'blog'}>
      <Grid centered={true}>
        <Grid.Column
          mobile={12}
          tablet={10}
          computer={8}
          largeScreen={8}
          widescreen={8}
          className={'page-wrapper-container'}
        >
          <h1 className={'page-wrapper-title'}>{'Blog'}</h1>
          {Posts}
        </Grid.Column>
      </Grid>
    </PageWrapper>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`;
